import React from 'react'

import './blob.scss'

export type BlobTypes = 'A' | 'B' | 'C'
export type BlobColors = 'Pink' | 'Yellow' | 'Blue' | 'DarkGreen' | 'Lightgrey'

interface Props {
  which: BlobTypes
  color: BlobColors
}

enum ColorEnum {
  Pink = '#dfe2ff',
  Yellow = 'rgb(255, 249, 186)',
  Blue = 'rgb(229,246,247)',
  DarkGreen = 'rgb(47, 116, 116)',
  Lightgrey = '#f8f6f6',
}

export const Blob: React.FC<Props> = ({ which, color }) => {
  const colorRGB = ColorEnum[color]

  switch (which) {
    case 'A':
      return (
        <svg
          className={'blob blob--a'}
          xmlns="http://www.w3.org/2000/svg"
          width="302.23"
          height="303.2"
          viewBox="0 0 258.32 259.21"
        >
          <defs>
            <clipPath id="blob-a-clip-path">
              <path
                d="M119.437 247.352c64.313-6.741 133.9-62.947 126.5-121.748S192.022-19.28 119.437 3.857 0 53.211 0 122.383s55.124 131.71 119.437 124.969z"
                className="blob-even-odd"
                fill={colorRGB}
              ></path>
            </clipPath>
            <clipPath id="blob-a-clip-path-2">
              <path d="M0 6063.267h1706.4V0H0z" fill={colorRGB}></path>
            </clipPath>
          </defs>
          <g>
            <g className="blob-a-cls-3">
              <g
                className="blob-a-cls-4"
                transform={'translate(-744.18 -2135.161)'}
              >
                <path
                  d="M0 0h258.33v259.221H0z"
                  fill={colorRGB}
                  transform={'translate(738.255 2129.476)'}
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case 'B':
      return (
        <svg
          className={'blob blob--b'}
          xmlns="http://www.w3.org/2000/svg"
          width="357.2"
          height="355.91"
          viewBox="0 0 269.26 259.21"
        >
          <defs>
            <clipPath id="blob-b-clip-path">
              <path
                d="M128.737 24.738c65.434 28.731 113.59-70.2 127.768-132.548S199.3-218 128.737-218 11.19-156.62.969-107.81 63.3-3.993 128.737 24.738z"
                className="blob-even-odd"
                fill={colorRGB}
                transform="translate(0 218)"
              ></path>
            </clipPath>
            <clipPath id="blob-b-clip-path-2">
              <path
                d="M-732 4082.266H844.19V-1981H-732z"
                fill={colorRGB}
                transform="translate(732 1981)"
              ></path>
            </clipPath>
          </defs>
          <g>
            <g className="blob-b-cls-3">
              <g
                className="blob-b-cls-4"
                transform="translate(-801.23 -2004.414)"
              >
                <path
                  d="M-5-223h269.266V36.221H-5z"
                  fill={colorRGB}
                  transform="translate(800.757 2221.729)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      )
    case 'C':
      return (
        <svg
          className={'blob blob--c'}
          xmlns="http://www.w3.org/2000/svg"
          width="312.966"
          height="310.55"
          viewBox="0 0 262.41 259.21"
        >
          <defs>
            <clipPath id="blob-c-clip-path">
              <path
                d="M126.467 27.62c69.318-14.125 120.252-45.894 124.792-115.934S192.615-208.643 126.52-217.207-12.371-163.745 1.782-88.314 57.149 41.745 126.467 27.62z"
                className="blob-even-odd"
                fill={colorRGB}
                transform="translate(0 218)"
              ></path>
            </clipPath>
            <clipPath id="blob-c-clip-path-2">
              <path
                d="M-550 4139.266h1574.546V-1924H-550z"
                fill={colorRGB}
                transform="translate(550 1924)"
              ></path>
            </clipPath>
          </defs>
          <g>
            <g className="blob-c-cls-3">
              <g
                className="blob-c-cls-4"
                transform="translate(-601.389 -1939.609)"
              >
                <path
                  d="M-5-223h262.424V36.221H-5z"
                  fill={colorRGB}
                  transform="translate(600.922 2156.924)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      )
    default:
      return null
  }
}
