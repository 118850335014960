/* eslint-disable */
import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES, NodeData } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { merge, has, get } from 'lodash/fp'
import ReactPlayer from 'react-player'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import { LazyImage } from './LazyImage'
import { Options } from '@contentful/rich-text-react-renderer'

interface Props {
  children: ReactNode
}

const Bold: React.FC<Props> = ({ children }) => <strong>{children}</strong>
const TextNode: React.FC<Props> = ({ children }) => <p>{children}</p>

export const createSlugs = (node: NodeData, slugs: string[] = []) => {
  const hasParentPage = has('parentPage', node)
  const parentPage = get('parentPage', node)
  const currentPageSlug: string = get('slug', node)
  if (!hasParentPage) {
    slugs.push(currentPageSlug)
  } else if (hasParentPage) {
    slugs.push(currentPageSlug)
    createSlugs(parentPage, slugs)
  }
  return slugs
}

export const createTitle = (node: any) => {
  const hasFieldTitle = has('fields.title.sv', node)
  const hasContentValue = has('content[0].value', node)
  if (hasContentValue) {
    return get('content[0].value', node)
  } else if (!hasContentValue && hasFieldTitle) {
    return node.fields.title.sv
  }

  return ''
}

export const getUrlPrefix = (node: NodeData) => {
  switch (node.data.target?.__typename) {
    case 'ContentfulBlogPost':
      return '/tips-och-trender/'
    case 'ContentfulPage':
      return ''
    default:
      return '/'
  }
}

const renderMarks = {
  [MARKS.BOLD]: (text: React.ReactNode) => <Bold>{text}</Bold>,
}

const renderNodes = {
  [BLOCKS.PARAGRAPH]: (
    node: any,
    children: React.ReactNode
  ): React.ReactNode => {
    return <TextNode>{children}</TextNode>
  },
  [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
    const hasFluid = has('data.target.file.fluid', node)
    const url = get('data.target.file.url', node)
    const alt = get('data.target.description', node)
    const width = get('data.target.file.details.image.width', node)
    const height = get('data.target.file.details.image.height', node)
    const fluid = get('data.target.file.fluid', node)
    const type = get('data.target.file.contentType', node) as string
    const isImage = type.includes('image')
    const isVideo = type.includes('video')

    if (isVideo) {
      return (
        <ReactPlayer
          url={url}
          playsinline
          pip={false}
          width="100%"
          height="auto"
          playing={true}
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      )
    } else if (isImage && hasFluid) {
      return <GatsbyImage image={fluid} alt={alt} />
    } else {
      return <LazyImage src={url} width={width} height={height} alt={alt} />
    }
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
    switch (node.data.target?.__typename) {
      case 'ContentfulHtmlCode':
        return (
          <div
            key={node.data.target.sys.id}
            dangerouslySetInnerHTML={{
              __html: node.data.target.code.code,
            }}
          />
        )
      default:
        console.log('unhandled embedded entry', node.data.target)
        return null
    }
  },
  [INLINES.ENTRY_HYPERLINK]: (
    node: Record<string, any>,
    children: React.ReactNode
  ): React.ReactNode => {
    const slug = createSlugs(node.data.target, []).reverse().join('/')
    const title = createTitle(node.data.target)
    const urlPrefix = getUrlPrefix(node)
    return (
      <Link to={`${urlPrefix}${slug}`} title={title}>
        {children}
      </Link>
    )
  },
}

const textRendered = (text: string) => {
  // Convert new lines into <br /> elements
  return text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [] as React.ReactNode[])
}

const defaultOptions = {
  renderMark: renderMarks,
  renderNode: renderNodes,
  renderText: textRendered,
}

export const createMarkupFromDocument = (data: any, options?: Options) => {
  if (!data) {
    return null
  }

  const config = merge(defaultOptions, options)
  return renderRichText(data, config)
}
