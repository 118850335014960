import React from 'react'
import { get } from 'lodash/fp'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

// Components
import { Blob, BlobTypes, BlobColors } from 'components/Blob'

// Utils
import { localizeDate } from 'utils/dates'

// Styling
import './blog-post.scss'

// Types
import { ContentfulBlogPost } from 'types/contentful'

type BlobPositions = 'below-meta' | 'image-right-bottom' | 'image-top-left'

export interface BlobConfig {
  position: BlobPositions
  which: BlobTypes
  color: BlobColors
}

export interface BlogPostProps {
  post: ContentfulBlogPost
  blob?: BlobConfig | false
  prefixUrl?: string
  mediaType?: 'fluid' | 'fixed' | 'only-src'
  mediaSrc?: string // only used if mediaType is only-src
}

export const BlogPost: React.FC<BlogPostProps> = ({
  post,
  blob,
  prefixUrl,
  mediaType = 'fluid',
  mediaSrc,
}) => {
  const href = prefixUrl ? `/${prefixUrl}/${post.slug}` : `/${post.slug}`
  const date = post.publishedAt && localizeDate(post.publishedAt)
  const blobPosition = blob ? blob.position : ''
  const classes = classNames('card card--blog', {
    'card--has-blob': blob !== false,
    [`card--blob-${blobPosition}`]: blob && blob.position,
  })

  return (
    <article className={classes}>
      {post.featuredMedia && post.featuredMedia.gatsbyImageData && (
        <Link to={href} title={`Läs om ${post.title}`}>
          <GatsbyImage
            image={post.featuredMedia.gatsbyImageData}
            className="card__image"
            style={{ width: '448px', height: 'auto', maxWidth: '100%' }}
            loading="lazy"
            alt=""
          />
        </Link>
      )}

      {post.featuredMedia && mediaType === 'fixed' && post.featuredMedia.fixed && (
        <Link to={href} title={`Läs om ${post.title}`}>
          <GatsbyImage
            image={post.featuredMedia.fixed}
            className="card__image"
            style={{ width: '448px', height: 'auto', maxWidth: '100%' }}
            loading="lazy"
            alt=""
          />
        </Link>
      )}

      {mediaSrc && mediaType === 'only-src' && (
        <Link to={href} title={`Läs om ${post.title}`}>
          <img
            className="card__image"
            style={{ width: '448px', height: 'auto', maxWidth: '100%' }}
            src={mediaSrc}
          />
        </Link>
      )}

      <div className="card__content">
        {date && (
          <time className="card__meta" dateTime={post.createdAt}>
            {date}
          </time>
        )}
        <Link
          to={href}
          title={`Läs om ${post.title}`}
          className="card__title-link"
        >
          <h3 className="card__title">{post.title}</h3>
        </Link>
        <p className="card__excerpt">{get('excerpt.excerpt', post)}</p>
        <Link to={href} title={`Läs om ${post.title}`} className={'card__link'}>
          Läs hela artikeln
        </Link>
        {blob &&
          (blob.position === 'below-meta' ||
            blob.position === 'image-right-bottom') && (
            <div className={`card__blob card__blob--${blob.position}`}>
              <Blob which={blob.which} color={blob.color} />
            </div>
          )}
      </div>
      {blob && blob.position === 'image-top-left' && (
        <div className={`card__blob card__blob--${blob.position}`}>
          <Blob which={blob.which} color={blob.color} />
        </div>
      )}
    </article>
  )
}

BlogPost.defaultProps = {
  blob: false,
  prefixUrl: '',
  mediaType: 'fluid',
}
