import React from 'react'

// Styling
import './blog-posts-list.scss'

// Components
import { BlogPost, BlogPostProps } from './BlogPost'

interface Props {
  children: React.ReactElement<typeof BlogPost>[]
}

export const BlogPosts: React.FC<Props> = ({ children }) => {
  const length = React.Children.count(children)
  let elements = null

  if (length === 1) {
    elements = children
  } else if (length >= 1) {
    elements = React.Children.map(children, (child, index) => {
      switch (index) {
        case 0: {
          const newProps: Partial<BlogPostProps> = {
            blob: {
              position: 'below-meta',
              which: 'A',
              color: 'Yellow',
            },
          }
          return React.cloneElement(child, newProps)
        }

        case 3: {
          const newProps: Partial<BlogPostProps> = {
            blob: {
              position: 'image-right-bottom',
              which: 'B',
              color: 'Pink',
            },
          }
          return React.cloneElement(child, newProps)
        }

        case 6: {
          const newProps: Partial<BlogPostProps> = {
            blob: {
              position: 'image-top-left',
              which: 'C',
              color: 'Yellow',
            },
          }
          return React.cloneElement(child, newProps)
        }

        default:
          return React.cloneElement(child)
      }
    })
  }

  return (
    <div id="blog-posts" className="blog-posts-list">
      {elements}
    </div>
  )
}
